export const SuccessToasterMessages = {
  LOGIN: "Logged in Succesfully!",
  USERVERIFIED: "User Verified Succesfully!",
  LOGOUT: "Logged Out Successfully!",
  VERIFYEMAIL: "Email Verified Successfully!",
  VERIFYOTP: "OTP Verified Successfully!",
  PASSWORDRESET: "Password Reset Successfully!",
  COMPLAINTCHANGE: "Complaint Status Changed",
  COMPLAINTCLOSE: "Complaint Closed Successfully",
  COMPLAINTRESOLVE: "Complaint Resolved Successfully",
  VENDORVISIT: "Vendor Visit Added Successfully",
  COMPLAINERNOTIFIED: "Complainer Notified Successfully",
  REQUESTREGISTERED: "Your Request Registered Successfully",
  REQUESTWITHDRAWN: "Request Withdrawn Successfully",
  PROFILEUPLOAD: "Profile Updated Successfully",
};

export const LoadingToasterMessages = {
  LOGIN: "Logging In",
  LOGOUT: "Logging Out",
  USERVERIFY: "Verifying User",
};

export const ErrorToasterMessages = {
  IMAGEUPLOADFAILED: "Image uploading failed , try after sometimes.",
  UPLOADFAILED: "Failed to update profile",
};
